/* eslint-disable no-console */
import * as Sentry from '@sentry/vue'

import genDamageId from '@/components/Map/utils/genDamageId'
import genGid from '@/components/Map/utils/genGid'
import genMatchPlayerId from '@/components/Map/utils/genMatchPlayerId'
import genRoundId from '@/components/Map/utils/genRoundId'
import genRoundPlayerId from '@/components/Map/utils/genRoundPlayerId'
import genRoundTeamId from '@/components/Map/utils/genRoundTeamId'
import {
  MAX_AGENT_SPEED,
  MAX_SMOKE_DISTANCE,
  MAX_SMOKE_DURATION,
  MAX_SMOKE_GAP,
  MAX_SMOKE_RADIUS_DISTANCE,
  MAX_TIME_DIFF,
  MAX_WALL_DISTANCE,
  MAX_WALL_DURATION,
  MAX_WALL_GAP,
} from '@/constants'
import { sort_by_score } from '@/helper.js'
import groupBy from '@/utils/groupBy'
import { isTradeKill, getTradedPuuid } from '@/utils/killfeed.js'
import reportException from '@/utils/reportException'
import stringCompare from '@/utils/stringCompare'

import binarySearch from './binarySearch.js'
import excludedAbilities from './excludedAbilities.js'
import { timeToMillis } from './timeToMillis.js'
import { getPointZone, getZones } from './zoning.js'

const aMoment = async delay => new Promise(resolve => setTimeout(resolve, delay))

async function binarySearchV(lo, hi, f) {
  const cache = []
  cache[lo] = await f(lo)
  cache[hi] = await f(hi)
  while (3 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    const li = lo + ((mi - lo) >> 1)
    cache[li] = cache[li] != null ? cache[li] : await f(li)
    const ri = mi + ((hi - mi) >> 1)
    cache[ri] = cache[ri] != null ? cache[ri] : await f(ri)
    if (cache[li] < cache[ri]) {
      hi = ri
    } else {
      lo = li
    }
  }
  return { best: lo, coef: cache[lo] }
}

const interpolateEvent = (prevEvent, nextEvent, currentTime) => {
  if (nextEvent.round_time_millis === currentTime) return nextEvent
  if (prevEvent.round_time_millis === currentTime) return prevEvent
  if (prevEvent.round_time_millis === nextEvent.round_time_millis) return
  if (prevEvent.round_time_millis > currentTime) return
  if (nextEvent.round_time_millis < currentTime) return

  const kDiff = nextEvent.round_time_millis - prevEvent.round_time_millis
  const kPrev = (nextEvent.round_time_millis - currentTime) / kDiff
  const kNext = (currentTime - prevEvent.round_time_millis) / kDiff

  if (kDiff > MAX_TIME_DIFF) return
  const speed =
    Math.sqrt(
      Math.pow(prevEvent.location.x - nextEvent.location.x, 2) +
        Math.pow(prevEvent.location.y - nextEvent.location.y, 2)
    ) / kDiff
  if (speed > MAX_AGENT_SPEED) return

  return {
    ...nextEvent,
    location: {
      x: prevEvent.location.x * kPrev + nextEvent.location.x * kNext,
      y: prevEvent.location.y * kPrev + nextEvent.location.y * kNext,
    },
    conf:
      (prevEvent.conf != null ? prevEvent.conf : 1) *
      (nextEvent.conf != null ? nextEvent.conf : 1) *
      ((1 - Math.min(1, kDiff / MAX_TIME_DIFF)) * 0.75 + 0.25),
  }
}

const getEventAt = (list, currentTime, { keyPointsMaxTimeDiff = -1 } = {}) => {
  const idx = binarySearch(list, event => event.round_time_millis >= currentTime)
  if (idx >= list.length) return
  const event = list[idx]
  const eventTime = event.round_time_millis
  if (eventTime === currentTime) return event
  if (idx === 0) return
  const prevEvent = list[idx - 1]
  const prevEventTime = prevEvent.round_time_millis
  if (
    keyPointsMaxTimeDiff !== -1 &&
    eventTime - currentTime > keyPointsMaxTimeDiff &&
    currentTime - prevEventTime > keyPointsMaxTimeDiff
  )
    return
  return interpolateEvent(
    { ...prevEvent, round_time_millis: prevEventTime },
    { ...event, round_time_millis: eventTime },
    currentTime
  )
}

const computeOffsetCoef = async (riotPositionsByTP, timeOffset, posForInt) => {
  let spaceOffset = 0
  let matchedPuuids = 0
  await Promise.all(
    Object.keys(riotPositionsByTP).map(async rt => {
      await aMoment()
      const dt = parseInt(rt, 10) + timeOffset

      await Promise.all(
        Object.keys(riotPositionsByTP[rt]).map(async puuid => {
          const riotPos = riotPositionsByTP[rt][puuid]
          const detPos = getEventAt(posForInt[puuid] || [], dt)
          if (detPos) {
            spaceOffset += Math.sqrt(
              (riotPos.location.x - detPos.location.x) ** 2 + (riotPos.location.y - detPos.location.y) ** 2
            )
            if (Number.isNaN(spaceOffset)) {
              console.log('nan from', rt, dt, puuid, riotPos, detPos)
            }
            matchedPuuids++
          }
        })
      )
    })
  )
  const coef = matchedPuuids > 10 ? spaceOffset / matchedPuuids : Number.MAX_SAFE_INTEGER
  return { coef, timeOffset, matchedPuuids, spaceOffset }
}

export const calcAvailablePositions = (
  riotPositions,
  detectedPositions,
  roundDuration,
  kills,
  offsetRiotPossitions = 0
) => {
  const detectedPositionsByPT = detectedPositions?.reduce((acc, pos) => {
    acc[pos.round_time_millis] = acc[pos.round_time_millis] || {}
    acc[pos.round_time_millis][pos.puuid] = pos
    return acc
  }, {})
  let deadPlayers = {}
  let killsIndex = 0
  let availablePositions = 0
  let totalPositions = 0
  for (let i = 0; i < roundDuration; i += 100) {
    availablePositions += Object.keys(detectedPositionsByPT?.[i] || {}).filter(puuid => !deadPlayers[puuid]).length
    while (kills[killsIndex]?.round_time_millis < i - offsetRiotPossitions) {
      deadPlayers[kills[killsIndex].victim_puuid] = true
      killsIndex++
    }
    totalPositions += Math.max(10 - killsIndex, 0)
  }
  // console.log(
  //   'availablePositions',
  //   roundId,
  //   roundDuration,
  //   availablePositions,
  //   totalPositions,
  //   ((availablePositions / totalPositions) * 100).toFixed(2) + '%',
  //   kills,
  //   detectedPositions
  // )
  return { availablePositions, totalPositions }
}

const findBestVideoOffset = async (riotPositions, posForInt) => {
  if (!posForInt || !riotPositions?.length || Object.values(posForInt).filter(l => l.length).length < 3) {
    return { best: 0, coef: 0, maxPuuids: 0 }
  }

  const riotPositionsByTP = riotPositions.reduce((acc, pos) => {
    acc[pos.round_time_millis] = acc[pos.round_time_millis] || {}
    if (acc[pos.round_time_millis][pos.puuid]) {
      console.error(
        'duplicate riot position',
        pos.puuid,
        pos.round_time_millis,
        pos,
        acc[pos.round_time_millis][pos.puuid]
      )
    }
    acc[pos.round_time_millis][pos.puuid] = pos
    return acc
  }, {})
  // console.log('findBestVideoOffset', riotPositionsByTP, detectedPositionsByPT)

  // console.log('maxTime overflow', maxTime - roundDuration)
  // console.log('sortedKills', sortedKills)
  // {
  //   let deadPlayers = 0
  //   let availablePositions = 0
  //   let totalPositions = 0
  //   for (let i = 0; i < maxTime; i += 100) {
  //     availablePositions += Object.keys(detectedPositionsByPT[i] || {}).length
  //     while (sortedKills[deadPlayers]?.round_time_millis < i) {
  //       deadPlayers++
  //     }
  //     totalPositions += Math.max(10 - deadPlayers, 0)
  //   }
  // }

  let maxPuuids = 0
  // let best = null

  // for (const riotTime in riotPositionsByTP) {
  //   for (const detectedTime in detectedPositionsByPT) {
  //     const timeOffset = parseInt(detectedTime, 10) - parseInt(riotTime, 10)
  //     const cur = computeOffsetCoef(riotPositionsByTP, timeOffset, posForInt)
  //
  //     if (cur.coef < 1) {
  //       cache[timeOffset] = cur.coef
  //       maxPuuids = Math.max(maxPuuids, cur.matchedPuuids)
  //       if (!best || best.coef > cur.coef) {
  //         best = cur
  //       }
  //     }
  //   }
  // }

  const { best, coef } = await binarySearchV(-5000, 5000, async timeOffset => {
    const cur = await computeOffsetCoef(riotPositionsByTP, timeOffset, posForInt)
    maxPuuids = Math.max(maxPuuids, cur.matchedPuuids)
    return cur.coef
  })

  console.log('best', best, coef, maxPuuids)

  return { best, coef, maxPuuids }
}

/**
 * @typedef {'defuse'|'plant'|'kill'|'position'|'death'|'utility'|'wall'|'smoke'} EventType
 */
/**
 * @typedef {{id: string, gid: string, type: EventType, match_id: string, round_id: string, round_time_millis: number, location: API_LOCATION, zone: string}} BasicEvent
 */
/**
 * @typedef {BasicEvent & {round_team_id: string}} TeamEvent
 */
/**
 * @typedef {TeamEvent & {match_player_id: string, round_player_id: string}} PlayerEvent
 */
/**
 * @typedef {{match_player_id: string, round_player_id: string, round_team_id: string, location: API_LOCATION}} KillPlayer
 */
/**
 * @typedef {{killer: KillPlayer, victim: KillPlayer, finishing_damage: {damage_type: string, damage_item: string}}} KillerVictimPart
 */
/**
 * @typedef {PlayerEvent & {type: 'death'} & KillerVictimPart} DeathEvent
 */
/**
 * @typedef {PlayerEvent & {type: 'defuse', site: string}} DefuseEvent
 */
/**
 * @typedef {BasicEvent & {type: 'plant', site: string}} PlantEvent
 */
/**
 * @typedef {PlayerEvent & {type: 'position'}} PositionEvent
 */
/**
 * @typedef {PlayerEvent & {type: 'kill'} & KillerVictimPart} KillEvent
 */
/**
 * @typedef {BasicEvent & {type: 'smoke', radius: number, duration: number}} SmokeEvent
 */
/**
 * @typedef {PlayerEvent & {type: 'utility', ability_slot: string}} UtilityEvent
 */
/**
 * @typedef {TeamEvent & {type: 'wall', vector: Array<API_LOCATION>}} WallEvent
 */
/**
 * @typedef {DeathEvent | DefuseEvent | PlantEvent | PositionEvent | KillEvent | SmokeEvent | UtilityEvent | WallEvent} Event
 */

/**
 * @typedef {{puuid: string, utility: string, count: number, oldCount: number}} UTILITY_USAGE_DATA
 */

/**
 * @typedef {{
 *   id: string,
 *   match_id: string,
 *   team_id: string,
 *   team_composition_id: string,
 *   win: boolean,
 *   grid: API_TEAM_GRID,
 *   score: number,
 * }} MATCH_TEAM
 */

const postProcessData = ({ rounds, ...rest }) => {
  // sort rounds by match and round_num
  rounds = Object.fromEntries(
    Object.entries(rounds).sort((a, b) =>
      a[1].match_id < b[1].match_id ? -1 : a[1].match_id > b[1].match_id ? 1 : a[1].round_num - b[1].round_num
    )
  )

  return {
    ...rest,
    rounds,
  }
}

/**
 * @param {Array<API_AGENT>} agentsData
 * @param {Array<API_GEAR>} gearsData
 * @param {Array<API_WEAPON>} weaponsData
 * @param {Array<API_MATCH_ANALYTICS_INFO>} matchesData
 * @param {Object<API_MATCH_ROUND_PLAYER_ORBS_INFO>} orbsData?
 * @param {Object<Array<PRE_DETECTED_POSITION>>} roundAdvancedPositionsData
 * @param {Object<Array<PRE_DETECTED_SMOKE>>} roundSmokesData
 * @param {Object<Array<PRE_DETECTED_UTILITY>>} roundUtilitiesData
 * @param {Object<Array<PRE_DETECTED_WALL>>} roundWallsData
 * @param {string} mapId
 * @param {boolean} [hasKillLocations]
 * @param {boolean} [reportMissingLocations]
 * @param {boolean} [isScrim]
 * @param {Object<Array<UTILITY_USAGE_DATA>>} roundUtilitiesUsageData
 * @return {{
 *   kills: Array<KillEvent>,
 *   gids: Object<{
 *     type: string,
 *     team_id?: string,
 *     player_id?: string,
 *     agent_id?: string,
 *     ability_slot?: string,
 *   }>,
 *   roundTeams: Object<{
 *     id: string,
 *     team_id: string,
 *     match_id: string,
 *     round_id: string,
 *     grid: string,
 *     role: string,
 *     eco: string,
 *     win: boolean,
 *   }>,
 *   roundPlayers: Object<{
 *     id: string,
 *     round_id: string,
 *     match_player_id: string,
 *     round_team_id: string,
 *     ultimate_orbs: (number|100|null),
 *   }>,
 *   teams: Object<{
 *     id: string,
 *     name: string,
 *     image: string,
 *     players: Array<string>,
 *   }>,
 *   players: Object<{
 *     id: string,
 *     name: string,
 *     is_active: boolean,
 *     team_id: string,
 *   }>,
 *   roundPlayerUtilities: Object<Object<Array<UtilityEvent>>>,
 *   advancedPositions: Array<PositionEvent>,
 *   positions: Array<PositionEvent>,
 *   teamCompositions: Record<string, {
 *     id: string,
 *     team_id: string,
 *     composition: Array<{
 *       agent_id: string,
 *       player_id: string,
 *     }>,
 *     match_players: Array<string>,
 *   }>,
 *   utilities: Array<UtilityEvent>,
 *   defuses: Array<DefuseEvent>,
 *   matches: Object<{
 *     id: string,
 *     vod_status: string,
 *     vod_platform: API_VOD_PLATFORM,
 *     vod_id: string,
 *     red_team_id: string,
 *     blue_team_id: string,
 *   }>,
 *   roundPlayerPositions: Object<Object<Array<PositionEvent>>>,
 *   agents,
 *   usedDamages: Object<Boolean>,
 *   walls: Array<WallEvent>,
 *   incompleteMlData: (boolean|*),
 *   smokes: Array<SmokeEvent>,
 *   plants: Array<PlantEvent>,
 *   firstRoundKills: Object<{
 *     round_time_millis: number,
 *     kill_id: string,
 *   }>,
 *   matchPlayers: Object<{
 *     id: string,
 *     agent_id: string,
 *     player_id: string,
 *     match_id,
 *     team_id,
 *     max_ultimate_orbs: number,
 *     team_composition_id: string,
 *   }>,
 *   deaths: Array<DeathEvent>,
 *   rounds: Object<{
 *     id: string,
 *     match_id: string,
 *     round_num: number,
 *     round_duration_millis: number,
 *     plant_site: string,
 *     plant: (Event|null),
 *     defuse: (Event|null),
 *     round_teams: Array<String>,
 *     outcome: string,
 *     vod_replay_url: string,
 *     vod_start_millis: number,
 *     vod_duration_millis: number,
 *   }>,
 * }}
 */
export default async function processMapToolData({
  staticData: { agentsData, weaponsData, gearsData },
  matchesData,
  orbsData,
  roundAdvancedPositionsData,
  roundSmokesData,
  roundUtilitiesData,
  roundWallsData,
  hasKillLocations = true,
  reportMissingLocations = true,
  isScrim = false,
  mapId,
  roundUtilitiesUsageData,
  excludeSomeAbilities = true,
  filterSuspiciousAdvancedPositions = true,
}) {
  const agents = Object.fromEntries(
    agentsData.map(agent => [
      agent.id,
      {
        ...agent,
        abilities: {
          ...Object.fromEntries(agent.abilities.map(ability => [ability.slot, ability])),
          ...Object.fromEntries(agent.abilities.map(ability => [ability.slot.toLowerCase(), ability])),
        },
      },
    ])
  )

  const weapons = Object.fromEntries(weaponsData.map(weapon => [weapon.id, weapon]))
  const gears = Object.fromEntries(gearsData.map(gear => [gear.id, gear]))

  /**
   * @type {Array<Readonly<DefuseEvent>>}
   */
  const defuses = []
  /**
   * @type {Array<Readonly<DeathEvent>>}
   */
  const deaths = []
  /**
   * @type {Array<Readonly<KillEvent>>}
   */
  const kills = []
  /**
   * @type {Array<Readonly<PositionEvent>>}
   */
  const positions = []
  /**
   * @type {Array<Readonly<PlantEvent>>}
   */
  const plants = []
  /**
   * @type {Array<Readonly<PositionEvent>>}
   */
  const advancedPositions = []
  /**
   * @type {Array<Readonly<SmokeEvent>>}
   */
  const smokes = []
  /**
   * @type {Array<Readonly<UtilityEvent>>}
   */
  const utilities = []
  /**
   * @type {Array<Readonly<WallEvent>>}
   */
  const walls = []
  const zones = getZones(mapId)
  /**
   * @type {Object<Boolean>}
   */
  const usedDamages = {}
  /**
   * @type {Object<{round_time_millis: number, kill_id: string}>}
   */
  const firstRoundKills = {}
  /**
   * round_id -> match_player_id -> position
   * @type {Object<Object<Array<PositionEvent>>>}
   */
  const roundPlayerPositions = {}
  /**
   * round_id -> match_player_id -> utility
   * @type {Object<Object<Array<UtilityEvent>>>}
   */
  const roundPlayerUtilities = {}
  /**
   * @type {Object<Readonly<{
   *   type: string,
   *   team_id?: string,
   *   player_id?: string,
   *   agent_id?: string,
   *   ability_slot?: string
   * }>>}
   */
  const gids = {}
  /**
   * @type {Object<Readonly<{
   *   id: string,
   *   agentId: string,
   *   playerId: string,
   *   teamId: string,
   *   maxUltimateOrbs: number,
   *   matchPlayerIds: Array<number>,
   *   matchIds: Array<number>
   * }>>}
   */
  const orbsPlayers = {}

  const roundStats = {}

  const utilitiesUsage = []

  const usedAbilities = {}

  const fixes = {}

  /**
   * @type {Record<string, MAP_TOOL_TEAM>}
   */
  const teams = {}
  /**
   *
   * @param {string} team_id
   * @param {API_MATCH_TEAM_INFO} teamData
   * @return {MAP_TOOL_TEAM}
   */
  const addTeam = (team_id, teamData) => {
    if (!teams[team_id]) {
      teams[team_id] = Object.freeze({
        id: team_id,
        abbr: teamData.abbr,
        image: teamData.image || teamData.logo_url,
        name: teamData.name,
        compositions: [],
        matches: [],
        match_teams: [],
        players: [],
        is_self_team: teamData.is_self_team,
      })
    }
    return teams[team_id]
  }

  /**
   * @type {Record<string, MAP_TOOL_PLAYER>}
   */
  const players = {}
  /**
   * @param {string} player_id
   * @param {API_MATCH_TEAM_PLAYER_INFO} playerData
   * @return {MAP_TOOL_PLAYER}
   */
  const addPlayer = (player_id, playerData) => {
    if (!players[player_id]) {
      // depends on the data source the player name is called differently
      // noinspection JSUnresolvedVariable
      const name = playerData.name || playerData.game_name || playerData.cleaned_riot_id || `unknown`
      players[player_id] = {
        id: player_id,
        name,
        team_players: [],
      }
    }
    return players[player_id]
  }

  /**
   * @type {Record<string, MAP_TOOL_TEAM_PLAYER>}
   */
  const teamPlayers = {}
  /**
   * @param {string} team_id
   * @param {string} player_id
   * @param {API_MATCH_TEAM_PLAYER_INFO} teamPlayerData
   * @return {MAP_TOOL_TEAM_PLAYER}
   */
  const addTeamPlayer = (team_id, player_id, teamPlayerData) => {
    const teamPlayerId = `${team_id}-${player_id}`
    if (!teamPlayers[teamPlayerId]) {
      const player = addPlayer(player_id, teamPlayerData)
      player.team_players.push(teamPlayerId)
      const team = teams[team_id]
      team.players.push(teamPlayerId)
      teamPlayers[teamPlayerId] = {
        id: teamPlayerId,
        player_id,
        team_id,
        is_active: teamPlayerData.is_active,
        match_players: [],
      }
    }
    return teamPlayers[teamPlayerId]
  }

  /**
   * @type {Record<string, MAP_TOOL_TEAM_COMPOSITION>}
   */
  const teamCompositions = {}
  /**
   * @param {string} team_id
   * @param {MAP_TOOL_COMPOSITION} composition
   * @return {MAP_TOOL_TEAM_COMPOSITION}
   */
  const addTeamComposition = (team_id, composition) => {
    const sortedComposition = [...composition].sort(
      stringCompare({ picker: ({ agent_id }) => agent_id, options: { numeric: false } })
    )
    const teamCompositionId = `${team_id}{${sortedComposition
      .map(({ agent_id, team_player_id }) => `${agent_id}+${team_player_id}`)
      .join(',')}}`
    if (!(teamCompositionId in teamCompositions)) {
      const team = teams[team_id]
      team.compositions.push(teamCompositionId)
      teamCompositions[teamCompositionId] = Object.freeze({
        id: teamCompositionId,
        team_id,
        composition: Object.freeze(sortedComposition),
        match_players: [],
        match_teams: [],
        matches: [],
      })
    }
    return teamCompositions[teamCompositionId]
  }

  /**
   * @type {Record<string, MAP_TOOL_MATCH_PLAYER>}
   */
  const matchPlayers = {}
  /**
   * @param {string} match_id
   * @param {string} team_id
   * @param {string} match_team_id
   * @param {API_MATCH_TEAM_PLAYER_INFO} matchPlayerData
   * @return {MAP_TOOL_MATCH_PLAYER|null}
   */
  const addMatchPlayer = (match_id, team_id, match_team_id, matchPlayerData) => {
    const teamPlayer = addTeamPlayer(team_id, matchPlayerData.id || matchPlayerData.puuid, matchPlayerData)
    /**
     * @type {string|null}
     */
    const matchPlayerId = genMatchPlayerId(match_id, teamPlayer.id)
    if (!matchPlayerId) {
      return null
    }
    if (!matchPlayers[matchPlayerId]) {
      teamPlayer.match_players.push(matchPlayerId)
      /**
       * find out how many ultimate orbs the agent can have
       * @type {(number|0|null)}
       */
      const max_ultimate_orbs =
        orbsData?.[match_id]?.reduce(
          (max, r) => Math.max(max, r.ultimates?.find(i => i.puuid === matchPlayerData.puuid)?.max || 0),
          0
        ) || null

      matchPlayers[matchPlayerId] = {
        id: matchPlayerId,
        agent_id: matchPlayerData.agent_id,
        match_id,
        match_team_id,
        player_id: teamPlayer.player_id,
        puuid: matchPlayerData.puuid,
        team_composition_id: null,
        team_id,
        team_player_id: teamPlayer.id,
        max_ultimate_orbs,
      }
    }
    return matchPlayers[matchPlayerId]
  }

  /**
   * @type {Record<string, MAP_TOOL_MATCH_TEAM>}
   */
  const matchTeams = {}
  /**
   * @param {string} match_id
   * @param {string} team_id
   * @param {API_MATCH_TEAM_INFO} matchTeamData
   * @return {MAP_TOOL_MATCH_TEAM}
   */
  const addMatchTeam = (match_id, team_id, matchTeamData) => {
    const matchTeamId = `${match_id}+${team_id}`
    if (!matchTeams[matchTeamId]) {
      const team = addTeam(team_id, matchTeamData)
      team.match_teams.push(matchTeamId)
      team.matches.push(match_id)
      const matchPlayers = matchTeamData.players.map(matchPlayerData =>
        addMatchPlayer(match_id, team_id, matchTeamId, matchPlayerData)
      )
      const teamComposition = addTeamComposition(
        team_id,
        matchPlayers.map(({ agent_id, team_player_id }) => ({ agent_id, team_player_id }))
      )
      matchPlayers.forEach(matchPlayer => {
        matchPlayer.team_composition_id = teamComposition.id
        teamComposition.match_players.push(matchPlayer.id)
      })
      matchTeams[matchTeamId] = {
        id: matchTeamId,
        match_id,
        team_id,
        team_composition_id: teamComposition.id,
        grid: matchTeamData.grid,
        score: matchTeamData.rounds_won,
        match_players: matchPlayers.map(({ id }) => id),
        round_match_teams: [],
      }
    }
    return matchTeams[matchTeamId]
  }

  /**
   * @type {MAP_TOOL_TOURNEY}
   */
  const events = {}
  /**
   * @param {null|API_EVENT_EXPAND} eventData
   * @return {null|MAP_TOOL_TOURNEY}
   */
  const addTourney = eventData => {
    if (!eventData) return null
    const eventId = eventData.id
    if (!events[eventId]) {
      events[eventId] = {
        id: eventId,
        logo_url: eventData.logo_url,
        name: eventData.name,
        matches: [],
      }
    }
    return events[eventId]
  }

  /**
   * @type {Record<string, MAP_TOOL_MATCH>}
   */
  const matches = {}
  /**
   * @param {string} match_id
   * @param {API_MATCH_ANALYTICS_INFO} matchData
   * @return {MAP_TOOL_MATCH}
   */
  const addMatch = (match_id, matchData) => {
    if (!matches[match_id]) {
      const tourney = addTourney(matchData.event_expand || matchData.event)
      if (tourney) {
        tourney.matches.push(match_id)
      }
      const _matchTeams = matchData.teams.map(matchTeamData => addMatchTeam(match_id, matchTeamData.id, matchTeamData))
      matches[match_id] = {
        id: match_id,
        created: matchData.created,
        tourney_id: tourney?.id,
        event: tourney,
        video_start_millis: timeToMillis(matchData.replay?.video_start_time),
        video_end_millis: timeToMillis(matchData.replay?.video_end_time),
        vod_id: matchData.replay?.video_id,
        vod_platform: matchData.replay?.video_platform,
        vod_status: matchData.replay?.cropper_status,
        match_teams: _matchTeams.map(({ id }) => id),
        rounds: [],
      }
    }
    return matches[match_id]
  }

  /**
   * @type {Record<string, MAP_TOOL_ROUND_TEAM>}
   */
  const roundTeams = {}
  /**
   * @param {string} match_id
   * @param {string} round_id
   * @param {API_MATCH_ROUND_TEAM} roundTeamData
   * @return {MAP_TOOL_ROUND_TEAM}
   */
  const addRoundTeam = (match_id, round_id, roundTeamData) => {
    const roundTeamId = genRoundTeamId(round_id, roundTeamData.id)
    if (!roundTeams[roundTeamId]) {
      const matchTeam = matches[match_id].match_teams
        .map(matchTeamId => matchTeams[matchTeamId])
        .find(({ team_id }) => team_id === roundTeamData.id)
      matchTeam.round_match_teams.push(roundTeamId)
      roundTeams[roundTeamId] = {
        id: roundTeamId,
        match_id,
        match_team_id: matchTeam.id,
        round_id,
        team_id: roundTeamData.id,
        grid: roundTeamData.grid,
        role: roundTeamData.role,
        eco: roundTeamData.eco,
        win: roundTeamData.won,
      }
    }
    return roundTeams[roundTeamId]
  }

  /**
   * @type {Record<string, MAP_TOOL_ROUND_PLAYER>}
   */
  const roundPlayers = {}
  /**
   * @param {string} match_id
   * @param {string} round_id
   * @param {string} match_player_id
   * @param {string} round_team_id
   * @param {API_MATCH_ROUND_INFO} roundData
   * @return {MAP_TOOL_ROUND_PLAYER}
   */
  const addRoundPlayer = (match_id, round_id, match_player_id, round_team_id, roundData) => {
    const roundPlayerId = genRoundPlayerId(round_id, match_player_id)
    if (!roundPlayers[roundPlayerId]) {
      const matchPlayer = matchPlayers[match_player_id]
      const roundTeam = roundTeams[round_team_id]

      const orbData = orbsData?.[match_id]
        ?.find(r => r.round_num === roundData.round_num)
        ?.ultimates?.find(i => i.puuid === matchPlayer.puuid)

      roundPlayers[roundPlayerId] = {
        id: roundPlayerId,
        round_id,
        match_player_id,
        round_team_id: roundTeam.id,
        match_team_id: roundTeam.match_team_id,
        team_id: roundTeam.team_id,
        puuid: matchPlayer.puuid,
        team_player_id: matchPlayer.team_player_id,
        ultimate_orbs: orbData
          ? orbData.count === orbData.max
            ? matchPlayers[match_player_id].max_ultimate_orbs
            : orbData.count
          : null,
      }
    }
    return roundPlayers[roundPlayerId]
  }

  /**
   * @type {Record<string, MAP_TOOL_ROUND>}
   */
  const rounds = {}
  /**
   * @param {string} match_id
   * @param roundData
   * @param advancedRoundData
   * @return {MAP_TOOL_ROUND}
   */
  const addRound = (match_id, roundData, advancedRoundData) => {
    const roundId = genRoundId(match_id, roundData.round_num)
    if (!rounds[roundId]) {
      const match = matches[match_id]
      match.rounds.push(roundId)

      const roundTeams = roundData.teams.map(roundTeamData => addRoundTeam(match_id, roundId, roundTeamData))

      const roundPlayers = match.match_teams.flatMap(matchTeamId => {
        const matchTeam = matchTeams[matchTeamId]
        const roundTeam = roundTeams.find(({ match_team_id }) => match_team_id === matchTeamId)
        return matchTeam.match_players.map(matchPlayerId =>
          addRoundPlayer(match_id, roundId, matchPlayerId, roundTeam.id, roundData)
        )
      })

      rounds[roundId] = {
        id: roundId,
        match_id,
        round_num: roundData.round_num,
        round_duration_millis: roundData.round_length_millis,
        plant_site: roundData.plant_side || null,
        plant_round_millis: roundData.plant_round_millis || null,
        outcome: roundData.result_code != null ? roundData.result_code || 'Time' : null,
        defuse_id: null,
        plant_id: null,
        vod_replay_url: advancedRoundData?.minimap_video_url,
        vod_broadcast_url: advancedRoundData?.replay_video_url,
        vod_end_millis: timeToMillis(advancedRoundData?.replay_video_end_time),
        vod_start_millis: timeToMillis(advancedRoundData?.replay_video_start_time),
        vod_duration_millis: roundData.round_length_millis,
        missing_kills: roundData.kills == null,
        round_players: roundPlayers.map(({ id }) => id),
        round_teams: roundTeams.map(({ id }) => id),
        round_player_count: [],
      }
    }
    return rounds[roundId]
  }

  /**
   * @param {'death'|'defuse'|'kill'|'plant'|'pos'|'smoke'|'util'|'utility-usage'|'wall'} type
   * @param {{[match_player_id]: string, [slot]: string, [round_team_id]: string}} [opts]
   * @return {string}
   */
  const addGid = (type, opts) => {
    switch (type) {
      case 'death':
      case 'defuse':
      case 'kill':
      case 'plant':
      case 'pos': {
        if (!opts.match_player_id) throw new Error(`Missing required match_player_id for gid type ${type}`)
        const matchPlayer = matchPlayers[opts.match_player_id]
        if (!matchPlayer) throw new Error(`Missing match player ${opts.match_player_id}`)
        const gid = genGid(type, matchPlayer.team_id, matchPlayer.player_id, matchPlayer.agent_id)
        if (!gids[gid]) {
          gids[gid] = Object.freeze({
            type: 'pos',
            team_id: matchPlayer.team_id,
            player_id: matchPlayer.player_id,
            agent_id: matchPlayer.agent_id,
          })
        }
        return gid
      }
      case 'smoke': {
        const gid = genGid(type)
        if (!gids[gid]) {
          gids[gid] = Object.freeze({
            type,
          })
        }
        return gid
      }
      case 'util':
      case 'utility-usage': {
        if (!opts.match_player_id) throw new Error(`Missing required match_player_id for gid type ${type}`)
        const matchPlayer = matchPlayers[opts.match_player_id]
        if (!matchPlayer) throw new Error(`Missing match player ${opts.match_player_id}`)
        const agent = agents[matchPlayer.agent_id]
        if (!agent) throw new Error(`Missing agent ${matchPlayer.agent_id}`)
        if (!opts.slot) throw new Error(`Missing required slot for gid type ${type}`)
        const gid = genGid(type, matchPlayer.team_id, matchPlayer.player_id, matchPlayer.agent_id, opts.slot)
        if (!gids[gid]) {
          gids[gid] = Object.freeze({
            type,
            team_id: matchPlayer.team_id,
            player_id: matchPlayer.player_id,
            agent_id: matchPlayer.agent_id,
            ability_slot: opts.slot,
          })
        }
        return gid
      }
      case 'wall': {
        if (!opts.round_team_id) throw new Error(`Missing required round_team_id for gid type ${type}`)
        const roundTeam = roundTeams[opts.round_team_id]
        if (!roundTeam) throw new Error(`Missing round team ${opts.round_team_id}`)
        const gid = genGid(type, roundTeam.team_id)
        if (!gids[gid]) {
          gids[gid] = Object.freeze({
            type,
            team_id: roundTeam.team_id,
          })
        }
        return gid
      }
      default:
        throw new Error(`Unhandled gid type ${type}`)
    }
  }

  await Promise.all(
    matchesData.map(async matchData => {
      const match = addMatch(matchData.id, matchData)
      const matchId = match.id

      /**
       * @type {Record<string, string>}
       */
      const puuidToMatchPlayerId = Object.fromEntries(
        match.match_teams.flatMap(teamId =>
          matchTeams[teamId].match_players.map(playerId => [matchPlayers[playerId].puuid, playerId])
        )
      )

      await Promise.all(
        matchData.rounds?.filter(Boolean)?.map(async roundData => {
          const round = addRound(
            match.id,
            roundData,
            matchData?.advancedData?.filter(Boolean).find(({ round_num } = {}) => round_num === roundData.round_num)
          )
          const roundId = round.id

          const posForInt = Object.fromEntries(
            Object.entries(
              roundAdvancedPositionsData?.[roundId]?.reduce((acc, pos) => {
                acc[pos.puuid] = acc[pos.puuid] || []
                acc[pos.puuid].push(pos)
                return acc
              }, {}) || {}
            ).map(([puuid, positions]) => [puuid, positions.sort((a, b) => a.round_time_millis - b.round_time_millis)])
          )

          const sortedKills = [...(roundData.kills || [])].sort((a, b) => a.round_time_millis - b.round_time_millis)

          const fix = (fixes[roundId] = await findBestVideoOffset(roundData.player_locations, posForInt))
          round.fixes = fix
          round.stats = {
            positions: calcAvailablePositions(
              roundData.player_locations,
              roundAdvancedPositionsData?.[roundId],
              roundData.round_length_millis,
              sortedKills,
              fix?.best
            ),
          }

          const fixDetectedTime = event => ({
            ...event,
            round_time_millis: event.round_time_millis - fixes[roundId].best,
          })

          /**
           * @type {Record<string, MAP_TOOL_ROUND_PLAYER>}
           */
          const puuidToRoundPlayer = Object.fromEntries(
            round.round_players.map(playerId => [roundPlayers[playerId].puuid, roundPlayers[playerId]])
          )

          /**
           * @param {string} puuid
           * @param {MAP_TOOL_ROUND_PLAYER} [roundPlayer]
           * @returns {{round_player_id: string, match_player_id: string, round_team_id: string}}
           */
          const mapPuuid = (puuid, roundPlayer = puuidToRoundPlayer[puuid]) => ({
            round_player_id: roundPlayer.id,
            match_player_id: roundPlayer.match_player_id,
            round_team_id: roundPlayer.round_team_id,
          })

          const plantLocation = Object.freeze(roundData.plant_location)
          const defuse =
            roundData.defuser_puuid && puuidToMatchPlayerId[roundData.defuser_puuid]
              ? Object.freeze({
                  id: `${roundId}-defuse`,
                  gid: addGid('defuse', { match_player_id: puuidToMatchPlayerId[roundData.defuser_puuid] }),
                  type: 'defuse',
                  match_id: matchId,
                  round_id: roundId,
                  round_time_millis: roundData.defuse_round_millis,
                  location: plantLocation,
                  ...mapPuuid(roundData.defuser_puuid),
                  site: roundData.plant_side,
                  zone: getPointZone(zones, plantLocation),
                })
              : null
          if (defuse) {
            defuses.push(Object.freeze(defuse))
            round.defuse_id = defuse.id
            round.defuse = defuse
          }
          const plant =
            roundData.planter_puuid && puuidToMatchPlayerId[roundData.planter_puuid]
              ? Object.freeze({
                  id: `${roundId}-plant`,
                  gid: addGid('plant', { match_player_id: puuidToMatchPlayerId[roundData.planter_puuid] }),
                  type: 'plant',
                  match_id: matchId,
                  round_id: roundId,
                  round_time_millis: roundData.plant_round_millis,
                  location: plantLocation,
                  ...mapPuuid(roundData.planter_puuid),
                  site: roundData.plant_side,
                  zone: getPointZone(zones, plantLocation),
                })
              : null
          if (plant) {
            plants.push(plant)
            round.plant_id = plant.id
            round.plant = plant
          }

          /**
           * @type {Record<API_TEAM_ROLE, string>}
           */
          const roundRoleToRoundTeamId = Object.fromEntries(
            round.round_teams.map(roundTeamId => [roundTeams[roundTeamId].role, roundTeamId])
          )

          roundStats[roundId] = Object.freeze(
            roundData.teams
              .map(roundTeamData => {
                const team = matchData.teams.find(teamData => teamData.id === roundTeamData.id)
                return {
                  id: roundTeamData.id,
                  name: team?.name || roundTeamData.name,
                  logo_url: team?.logo_url,
                  score: team?.rounds_won,
                  players: roundData.player_stats
                    ?.filter(playerStat => playerStat.team_side === team?.team_side)
                    .map(playerStat => {
                      const player = team.players?.find(p => p.puuid === playerStat.puuid)
                      return {
                        assists: playerStat.assists,
                        avr_round_score: player?.avr_round_score,
                        damage:
                          playerStat.damages?.reduce(
                            (acc, damage) => {
                              acc.bodyshots += damage.bodyshots || 0
                              acc.headshots += damage.headshots || 0
                              acc.legshots += damage.legshots || 0
                              acc.total += damage.damage || 0
                              return acc
                            },
                            {
                              bodyshots: 0,
                              headshots: 0,
                              legshots: 0,
                              total: 0,
                            }
                          ) || {},
                        kills: playerStat.kills,
                        loadout: playerStat.loadout_value,
                        name: player?.game_name,
                        puuid: playerStat.puuid,
                        remaining: playerStat.remaining,
                        spent: playerStat.spent,
                        ultimates: orbsData?.[matchId]
                          ?.find(roundOrbs => roundOrbs.round_num === roundData.round_num)
                          ?.ultimates?.find(orbData => orbData.puuid === player?.puuid),
                        agent_name: agents[player?.agent_id]?.name,
                        agent_icon_url: agents[player?.agent_id]?.display_icon_url,
                        armor: playerStat.armor_id,
                        armor_name: gears[playerStat.armor_id?.toLowerCase()]?.name,
                        armor_url: gears[playerStat.armor_id?.toLowerCase()]?.display_icon_url,
                        weapon: playerStat.weapon_id,
                        weapon_name: weapons[playerStat.weapon_id?.toLowerCase()]?.name,
                        deaths: (roundData.kills || []).reduce((acc, kill) => {
                          if (kill.victim_puuid === playerStat.puuid) {
                            acc += 1
                          }
                          return acc
                        }, 0),
                      }
                    }),
                }
              })
              .sort(sort_by_score)
          )

          const playerCount = Object.fromEntries(
            round.round_teams.map(roundTeamId => [roundTeamId, { role: roundTeams[roundTeamId].role, count: 5 }])
          )
          let currTime = 0
          let prevTime = 0
          let planted = false

          sortedKills.forEach((killData, idx, arr) => {
            let post_mortem = false
            if (!puuidToMatchPlayerId[killData.killer_puuid]) {
              console.debug(
                `[/map/full/${roundId}@${killData.round_time_millis}] Missing killer_puuid ${killData.killer_puuid} in match players`,
                killData
              )
              reportException(
                `Missing killer puuid "${killData.killer_puuid}" in match players for match ${matchId} round ${roundData.round_num} at ${killData.round_time_millis}`,
                { matchData, roundData, killData }
              )
              return
            }
            if (!puuidToMatchPlayerId[killData.victim_puuid]) {
              console.debug(
                `[/map/full/${roundId}@${killData.round_time_millis}] Missing victim_puuid ${killData.victim_puuid} in match players`,
                killData
              )
              reportException(
                `Missing victim puuid "${killData.victim_puuid}" in match players for match ${matchId} round ${roundData.round_num} at ${killData.round_time_millis}`,
                { matchData, roundData, killData }
              )
              return
            }
            if (!killData.killer_location && hasKillLocations) {
              // try to find previous event where the killer was killed and use that as location
              killData.killer_location = roundData.kills.find(killData2 => {
                if (killData2.victim_puuid === killData.killer_puuid) {
                  if (killData2.round_time_millis <= killData.round_time_millis) {
                    return true
                  } else {
                    if (reportMissingLocations) {
                      const agent = agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id]
                      reportException(
                        `Missing alive killer location for ${agent.name} with ${killData.finishing_damage.damage_type} ${killData.finishing_damage.damage_item} on match ${matchId} round ${roundData.round_num} at ${killData.round_time_millis}`,
                        { matchData, roundData, killData, agent }
                      )
                    }
                  }
                }
              })?.victim_location
              if (!isScrim) {
                post_mortem = true
              }
            }

            if (!killData.killer_location && hasKillLocations && reportMissingLocations) {
              console.debug(
                `[/map/full/${roundId}@${killData.round_time_millis}] Missing killer location for kill ${killData.finishing_damage.damage_type}`,
                killData
              )
              if (killData.finishing_damage.damage_type !== 'Bomb') {
                const agent = agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id]
                reportException(
                  `Missing killer location for ${agent.name} with ${killData.finishing_damage.damage_type} ${killData.finishing_damage.damage_item} on match ${matchId} round ${roundData.round_num} at ${killData.round_time_millis}`,
                  { matchData, roundData, killData, agent }
                )
              }
            }

            // Fix Breach ability in kill data
            if (
              agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].name === 'Breach' &&
              killData.finishing_damage.damage_type === 'Ability'
            ) {
              killData.finishing_damage.damage_item = 'Grenade'
            }

            // Fix Chamber ability in kill data
            if (
              agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].name === 'Chamber' &&
              killData.finishing_damage.damage_type === 'Weapon' &&
              killData.finishing_damage.damage_item === ''
            ) {
              killData.finishing_damage.damage_type = 'Ability'
              killData.finishing_damage.damage_item = 'Ultimate'
            }

            // try to fix Ability suffix
            if (
              killData.finishing_damage.damage_type === 'Ability' &&
              !agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].abilities[
                killData.finishing_damage.damage_item
              ]
            ) {
              if (killData.finishing_damage.damage_item.endsWith('Ability')) {
                killData.finishing_damage.damage_item = killData.finishing_damage.damage_item.replaceAll(
                  /Ability$/g,
                  ''
                )
              }
            }

            if (
              killData.finishing_damage.damage_type === 'Ability' &&
              !agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].abilities[
                killData.finishing_damage.damage_item
              ]
            ) {
              console.debug(
                `[/map/full/${roundId}@${killData.round_time_millis}] Missing ability ${
                  killData.finishing_damage.damage_item
                } in agent ${
                  agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].name
                } abilities`,
                killData
              )
              Sentry.captureException(
                new Error(
                  `[/map/full/${roundId}@${killData.round_time_millis}] Missing ability ${
                    killData.finishing_damage.damage_item
                  } in agent ${
                    agents[matchPlayers[puuidToMatchPlayerId[killData.killer_puuid]].agent_id].name
                  } abilities`
                )
              )
              return
            }

            //Round player count
            if (currTime !== killData.round_time_millis) {
              prevTime = currTime
              currTime = killData.round_time_millis

              let stable = currTime - prevTime > 3000 ? true : false
              if (
                plant &&
                !planted &&
                currTime >= roundData.plant_round_millis &&
                prevTime <= roundData.plant_round_millis &&
                roundData.plant_round_millis < roundData.round_length_millis
              ) {
                planted = true
                if (!stable) {
                  round.round_player_count.push(
                    Object.freeze({
                      match_id: matchId,
                      round_id: roundId,
                      state_start: roundData.plant_round_millis,
                      planted: planted,
                      stable: stable,
                      state: JSON.parse(JSON.stringify(playerCount)),
                    })
                  )
                }
              }
              if (
                stable &&
                roundId in firstRoundKills &&
                firstRoundKills[roundId].round_time_millis !== killData.round_time_millis &&
                playerCount[round.round_teams[0]].count > 0 &&
                playerCount[round.round_teams[1]].count > 0
              ) {
                round.round_player_count.push(
                  Object.freeze({
                    match_id: matchId,
                    round_id: roundId,
                    state_start: prevTime,
                    planted: planted,
                    stable: stable,
                    state: JSON.parse(JSON.stringify(playerCount)),
                  })
                )
              }
            }
            playerCount[puuidToRoundPlayer[killData.victim_puuid].round_team_id].count -= 1

            // convert to use advanced positions if available
            killData.killer_location =
              getEventAt(posForInt[killData.killer_puuid] || [], killData.round_time_millis + fixes[roundId].best, {
                keyPointsMaxTimeDiff: 1000,
              })?.location || killData.killer_location
            killData.victim_location =
              getEventAt(posForInt[killData.victim_puuid] || [], killData.round_time_millis + fixes[roundId].best, {
                keyPointsMaxTimeDiff: 1000,
              })?.location || killData.victim_location

            const killId = `k-${roundId}#${killData.round_time_millis}-${killData.victim_puuid}`
            const deathId = `d-${roundId}#${killData.round_time_millis}-${killData.victim_puuid}`
            const commonData = {
              match_id: matchId,
              round_id: roundId,
              round_time_millis: killData.round_time_millis,
              post_mortem: post_mortem,
              killer: Object.freeze({
                ...mapPuuid(killData.killer_puuid),
                location: Object.freeze(killData.killer_location),
                zone: getPointZone(zones, killData.killer_location),
              }),
              finishing_damage: Object.freeze({
                damage_type: killData.finishing_damage.damage_type,
                damage_item: killData.finishing_damage.damage_item,
              }),
              victim: Object.freeze({
                ...mapPuuid(killData.victim_puuid),
                location: Object.freeze(killData.victim_location),
                zone: getPointZone(zones, killData.victim_location),
              }),
              is_trade: isTradeKill(
                killData,
                arr.filter(
                  p =>
                    puuidToRoundPlayer[killData.victim_puuid].round_team_id !==
                      puuidToRoundPlayer[killData.killer_puuid].round_team_id &&
                    p !== killData &&
                    p.round_time_millis <= killData.round_time_millis &&
                    killData.finishing_damage.damage_type !== 'Bomb'
                )
              ),
              traded: isTradeKill(
                killData,
                arr.filter(
                  p =>
                    puuidToRoundPlayer[killData.victim_puuid].round_team_id !==
                      puuidToRoundPlayer[killData.killer_puuid].round_team_id &&
                    p !== killData &&
                    p.round_time_millis <= killData.round_time_millis &&
                    killData.finishing_damage.damage_type !== 'Bomb'
                )
              )
                ? mapPuuid(
                    getTradedPuuid(
                      killData,
                      arr.filter(
                        p =>
                          p !== killData &&
                          p.round_time_millis <= killData.round_time_millis &&
                          killData.finishing_damage.damage_type !== 'Bomb'
                      )
                    )
                  )
                : '',
            }
            kills.push(
              Object.freeze({
                ...commonData,
                ...commonData.killer,
                id: killId,
                gid: addGid('kill', { match_player_id: commonData.killer.match_player_id }),
                rgid: addGid('death', { match_player_id: commonData.victim.match_player_id }),
                type: 'kill',
              })
            )
            deaths.push(
              Object.freeze({
                ...commonData,
                ...commonData.victim,
                id: deathId,
                gid: addGid('death', { match_player_id: commonData.victim.match_player_id }),
                rgid: addGid('kill', { match_player_id: commonData.killer.match_player_id }),
                type: 'death',
              })
            )
            usedDamages[
              genDamageId(killData.finishing_damage, agents[matchPlayers[commonData.killer.match_player_id].agent_id])
            ] = true
            if (
              !(roundId in firstRoundKills) ||
              firstRoundKills[roundId].round_time_millis > killData.round_time_millis
            ) {
              firstRoundKills[roundId] = Object.freeze({
                round_time_millis: killData.round_time_millis,
                kill_id: killId,
              })
            }
          })
          /**
           * @param {API_PLAYER_POSITION} positionData
           * @return {Readonly<PositionEvent>|null}
           */
          const processPosition = positionData => {
            if (!puuidToMatchPlayerId[positionData.puuid]) {
              console.debug(
                `[/map/full/${roundId}@${positionData.round_time_millis}] Missing position.puuid ${positionData.puuid} in match players`
              )
              Sentry.captureException(
                new Error(
                  `[/map/full/${roundId}@${positionData.round_time_millis}] Missing position.puuid ${positionData.puuid} in match players`
                )
              )
              return null
            }
            const positionId =
              `${roundId}#${positionData.round_time_millis}-${positionData.puuid}` +
              (positionData.track_id ? `#${positionData.track_id}` : '')
            const puuidMapping = mapPuuid(positionData.puuid)
            // noinspection UnnecessaryLocalVariableJS
            /**
             * @type {PositionEvent}
             */
            const position = {
              id: positionId,
              gid: addGid('pos', puuidMapping),
              type: 'position',
              match_id: matchId,
              round_id: roundId,
              round_time_millis: positionData.round_time_millis,
              ...puuidMapping,
              location: {
                x: positionData.location.x,
                y: positionData.location.y,
                view_radians: positionData.view_radians,
              },
              zone: getPointZone(zones, positionData.location),
              ...(positionData.track_id ? { track_id: positionData.track_id } : {}),
            }
            return position
          }
          roundData.player_locations
            ?.map(processPosition)
            .filter(Boolean)
            .map(p => Object.freeze(p))
            .forEach(position => {
              // noinspection DuplicatedCode
              positions.push(position)
              roundPlayerPositions[roundId] = roundPlayerPositions[roundId] || {}
              roundPlayerPositions[roundId][position.match_player_id] =
                roundPlayerPositions[roundId][position.match_player_id] || []
              roundPlayerPositions[roundId][position.match_player_id].push(position)
            })
          roundAdvancedPositionsData?.[roundId]
            ?.map(processPosition)
            .filter(Boolean)
            .map(fixDetectedTime)
            .forEach(position => {
              advancedPositions.push(Object.freeze(position))
            })
          if (roundSmokesData?.[roundId]) {
            const mergedSmokes = []
            const distance = (a, b) =>
              Math.sqrt((a.location.x - b.location.x) ** 2 + (a.location.y - b.location.y) ** 2)
            const sortedSmokesData = [...roundSmokesData[roundId]]
              .sort((a, b) => a.round_time_millis - b.round_time_millis)
              .map(fixDetectedTime)
            sortedSmokesData.forEach(smokeData => {
              const {
                smoke: existingSmoke,
                dist,
                gap,
              } = mergedSmokes.reduce((best, curr) => {
                const gap = smokeData.round_time_millis - curr.round_time_millis
                if (gap === 0 || gap > MAX_SMOKE_GAP) {
                  return best
                }

                const duration = smokeData.round_time_millis - curr.start
                if (duration > MAX_SMOKE_DURATION) {
                  return best
                }

                const dist = distance(curr, smokeData)
                if (
                  dist > curr.radius * MAX_SMOKE_RADIUS_DISTANCE ||
                  dist > smokeData.radius * MAX_SMOKE_RADIUS_DISTANCE ||
                  dist > MAX_SMOKE_DISTANCE
                ) {
                  return best
                }

                if (!best || dist < best.dist) {
                  return { dist, gap, smoke: curr }
                }

                return best
              }, null) || {}

              if (!existingSmoke) {
                mergedSmokes.push({
                  ...smokeData,
                  maxDist: 0,
                  maxGap: 0,
                  start: smokeData.round_time_millis,
                  end: smokeData.round_time_millis,
                  smokes: [smokeData],
                })
              } else {
                Object.assign(existingSmoke, smokeData)
                existingSmoke.maxDist = Math.max(existingSmoke.maxDist, dist)
                existingSmoke.maxGap = Math.max(existingSmoke.maxGap, gap)
                existingSmoke.end = smokeData.round_time_millis
                existingSmoke.smokes.push(smokeData)
              }
            })

            const filteredSmokes = mergedSmokes.filter(smokes => smokes.smokes.length > 1)

            filteredSmokes.forEach((smokeData, idx) => {
              const id =
                `${roundId}#${smokeData.round_time_millis}-${idx}` +
                (smokeData.track_id ? `#${smokeData.track_id}` : '')
              const location = smokeData.smokes.reduce(
                (acc, curr, idx) => ({
                  x: (acc.x * idx + curr.location.x) / (idx + 1),
                  y: (acc.y * idx + curr.location.y) / (idx + 1),
                }),
                { x: 0, y: 0 }
              )
              const radius = smokeData.smokes.reduce((acc, curr, idx) => (acc * idx + curr.radius) / (idx + 1), 0)
              smokes.push(
                Object.freeze({
                  id,
                  gid: addGid('smoke'),
                  type: 'smoke',
                  match_id: matchId,
                  round_id: roundId,
                  round_time_millis: smokeData.start,
                  duration: smokeData.end - smokeData.start,
                  location,
                  radius,
                  zone: getPointZone(zones, location),
                  ...(smokeData.track_id ? { track_id: smokeData.track_id } : {}),
                })
              )
            })
          }

          if (roundUtilitiesUsageData?.[roundId]) {
            const counts = {}
            roundUtilitiesUsageData[roundId]
              ?.filter(utilityUsage => {
                const agent = agents[matchPlayers[puuidToMatchPlayerId[utilityUsage.puuid]].agent_id]

                if (
                  excludeSomeAbilities &&
                  excludedAbilities.includes(agent.abilities[utilityUsage.utility]?.displayName)
                ) {
                  return false
                }

                if (utilityUsage.utility === 'ultimate') {
                  return utilityUsage.count === 0 && utilityUsage.oldCount > 0
                } else {
                  return utilityUsage.count < utilityUsage.oldCount
                }
              })
              .map(fixDetectedTime)
              .forEach(utilityUsageData => {
                const k = `${utilityUsageData.round_time_millis}`
                counts[k] = (counts[k] || 0) + 1
                const id =
                  `${roundId}#${utilityUsageData.round_time_millis}-${counts[k]}` +
                  (utilityUsageData.track_id ? `#${utilityUsageData.track_id}` : '')
                const puuidMapping = mapPuuid(utilityUsageData.puuid)
                utilitiesUsage.push(
                  Object.freeze({
                    ...puuidMapping,
                    id,
                    gid: addGid('utility-usage', {
                      match_player_id: puuidMapping.match_player_id,
                      slot: utilityUsageData.utility,
                    }),
                    type: 'utility-usage',
                    match_id: matchId,
                    round_id: roundId,
                    round_time_millis: utilityUsageData.round_time_millis,
                    utility: utilityUsageData.utility,
                    count: utilityUsageData.count,
                    old_count: utilityUsageData.oldCount,
                    puuid: utilityUsageData.puuid,
                    max_count: utilityUsageData.maxCount,
                    old_max_count: utilityUsageData.oldMaxCount,
                    agent_id: matchPlayers[puuidToMatchPlayerId[utilityUsageData.puuid]].agent_id,
                    ...(utilityUsageData.track_id ? { track_id: utilityUsageData.track_id } : {}),
                  })
                )
                usedAbilities[
                  genDamageId(
                    { damage_type: 'Ability', damage_item: utilityUsageData.utility },
                    agents[matchPlayers[puuidToMatchPlayerId[utilityUsageData.puuid]].agent_id]
                  )
                ] = true
              })
          }

          if (roundUtilitiesData?.[roundId]) {
            roundUtilitiesData[roundId].map(fixDetectedTime).forEach(utilityData => {
              if (!puuidToMatchPlayerId[utilityData.puuid]) {
                console.debug(
                  `[/map/full/${roundId}@${utilityData.round_time_millis}.utilities] Missing puuid ${utilityData.puuid} in match players`,
                  utilityData
                )
                Sentry.captureException(
                  new Error(
                    `[/map/full/${roundId}@${utilityData.round_time_millis}.utilities] Missing puuid ${utilityData.puuid} in match players`
                  )
                )
                return
              }
              // try to fix Ability suffix
              if (
                !agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].abilities[
                  utilityData.ability_slot
                ]
              ) {
                if (utilityData.ability_slot.endsWith('ability')) {
                  utilityData.ability_slot = utilityData.ability_slot.replaceAll(/Ability$/g, '')
                }
              }
              if (
                !agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].abilities[
                  utilityData.ability_slot
                ]
              ) {
                console.debug(
                  `[/map/full/${roundId}@${utilityData.round_time_millis}.utilities] Missing ability ${
                    utilityData.ability_slot
                  } in agent ${agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].name} abilities`,
                  utilityData
                )
                Sentry.captureException(
                  new Error(
                    `[/map/full/${roundId}@${utilityData.round_time_millis}.utilities] Missing ability ${
                      utilityData.ability_slot
                    } in agent ${agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].name} abilities`
                  )
                )
                return
              }
              // ignore abilities with lots of false-positives
              if (process.env.VUE_APP_HIDE_BAD_UTILITIES === 'yes') {
                if (
                  ['Owl Drone'].includes(
                    agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].abilities[
                      utilityData.ability_slot
                    ].displayName
                  )
                ) {
                  return
                }
                if (
                  ['Recon Bolt'].includes(
                    agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].abilities[
                      utilityData.ability_slot
                    ].displayName
                  )
                ) {
                  return
                }
                if (
                  ['Dark Cover'].includes(
                    agents[matchPlayers[puuidToMatchPlayerId[utilityData.puuid]].agent_id].abilities[
                      utilityData.ability_slot
                    ].displayName
                  )
                ) {
                  return
                }
              }
              const utilityId =
                `${roundId}#${utilityData.round_time_millis}-${utilityData.puuid}.${utilityData.ability_slot}` +
                (utilityData.track_id ? `#${utilityData.track_id}` : '')
              const puuidMapping = mapPuuid(utilityData.puuid)
              const utility = Object.freeze({
                id: utilityId,
                gid: addGid('util', { ...puuidMapping, slot: utilityData.ability_slot }),
                type: 'utility',
                ability_slot: utilityData.ability_slot,
                match_id: matchId,
                round_id: roundId,
                round_time_millis: utilityData.round_time_millis,
                ...puuidMapping,
                location: utilityData.location,
                zone: getPointZone(zones, utilityData.location),
                ...(utilityData.track_id ? { track_id: utilityData.track_id } : {}),
              })
              // noinspection DuplicatedCode
              utilities.push(utility)
              roundPlayerUtilities[roundId] = roundPlayerUtilities[roundId] || {}
              roundPlayerUtilities[roundId][utility.match_player_id] =
                roundPlayerUtilities[roundId][utility.match_player_id] || []
              roundPlayerUtilities[roundId][utility.match_player_id].push(utility)

              usedDamages[
                genDamageId(
                  { damage_type: 'Ability', damage_item: utility.ability_slot },
                  agents[matchPlayers[utility.match_player_id].agent_id]
                )
              ] = true
            })
          }
          if (roundWallsData?.[roundId]) {
            const mergedWalls = []
            const d = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2)
            const distance = (a, b) =>
              Math.min(
                d(a.vector[0], b.vector[0]) + d(a.vector[1], b.vector[1]),
                d(a.vector[1], b.vector[0]) + d(a.vector[0], b.vector[1])
              )
            const sortedWalls = roundWallsData[roundId]
              .filter(wallData => {
                if (!roundRoleToRoundTeamId[wallData.role.toLowerCase()]) {
                  console.debug(
                    `[/map/full/${roundId}@${wallData.round_time_millis}.walls] Missing team role ${wallData.role} in match`,
                    wallData
                  )
                  Sentry.captureException(
                    new Error(
                      `[/map/full/${roundId}@${wallData.round_time_millis}.walls] Missing team role ${wallData.role} in match`
                    )
                  )
                  return false
                }
                return true
              })
              .sort((a, b) => a.round_time_millis - b.round_time_millis)
              .map(fixDetectedTime)
            sortedWalls.forEach(wallData => {
              const {
                wall: existingWall,
                dist,
                gap,
              } = mergedWalls.reduce((best, curr) => {
                if (curr.role !== wallData.role) {
                  return best
                }

                const gap = wallData.round_time_millis - curr.round_time_millis
                if (gap === 0 || gap > MAX_WALL_GAP) {
                  return best
                }

                const duration = wallData.round_time_millis - curr.start
                if (duration > MAX_WALL_DURATION) {
                  return best
                }

                const dist = distance(curr, wallData)
                if (dist > MAX_WALL_DISTANCE) {
                  return best
                }

                if (!best || dist < best.dist) {
                  return { dist, gap, wall: curr }
                }

                return best
              }, null) || {}

              if (!existingWall) {
                mergedWalls.push({
                  ...wallData,
                  maxDist: 0,
                  maxGap: 0,
                  start: wallData.round_time_millis,
                  end: wallData.round_time_millis,
                  walls: [wallData],
                })
              } else {
                Object.assign(existingWall, wallData)
                existingWall.maxDist = Math.max(existingWall.maxDist, dist)
                existingWall.maxGap = Math.max(existingWall.maxGap, gap)
                existingWall.end = wallData.round_time_millis
                existingWall.walls.push(wallData)
              }
            })

            const filteredWalls = mergedWalls.filter(walls => walls.walls.length > 1)

            filteredWalls.forEach((wallData, index) => {
              const wallId =
                `${roundId}#${wallData.round_time_millis}-${wallData.role}#${index}` +
                (wallData.track_id ? `#${wallData.track_id}` : '')
              const location = wallData.walls.reduce(
                (acc, curr, idx) => ({
                  x: (acc.x * idx + curr.location.x) / (idx + 1),
                  y: (acc.y * idx + curr.location.y) / (idx + 1),
                }),
                { x: 0, y: 0 }
              )
              const vector = wallData.walls.reduce((acc, curr, idx) => {
                if (!acc) return curr.vector
                const dist1 = d(acc[0], curr.vector[0]) + d(acc[1], curr.vector[1])
                const dist2 = d(acc[1], curr.vector[0]) + d(acc[0], curr.vector[1])
                const v = dist1 < dist2 ? curr.vector : curr.vector.reverse()
                return acc.map((l, i) => ({
                  x: (l.x * idx + v[i].x) / (idx + 1),
                  y: (l.y * idx + v[i].y) / (idx + 1),
                }))
              }, null)
              const wall = Object.freeze({
                id: wallId,
                gid: addGid('wall', { round_team_id: roundRoleToRoundTeamId[wallData.role.toLowerCase()] }),
                type: 'wall',
                match_id: matchId,
                round_id: roundId,
                round_time_millis: wallData.start,
                duration: wallData.end - wallData.start,
                match_player_id: /** @type {string} */ null,
                location,
                vector,
                round_team_id: roundRoleToRoundTeamId[wallData.role.toLowerCase()],
                zone: getPointZone(zones, location),
                ...(wallData.track_id ? { track_id: wallData.track_id } : {}),
              })
              walls.push(wall)
            })
          }
        }) || []
      )
    })
  )

  if (matchPlayers) {
    /**
     * @type {Record<string, Array<MAP_TOOL_MATCH_PLAYER>>}
     */
    const groupedByPlayerAgentOrbs = Object.values(matchPlayers)
      .filter(matchPlayer => matchPlayer.max_ultimate_orbs)
      .reduce((acc, matchPlayer) => {
        const key = `p${matchPlayer.team_player_id}#a${matchPlayer.agent_id}#orbs${matchPlayer.max_ultimate_orbs}`
        acc[key] = acc[key] || []
        acc[key].push(matchPlayer)
        return acc
      }, {})
    Object.entries(groupedByPlayerAgentOrbs).forEach(([key, matchPlayers]) => {
      orbsPlayers[key] = Object.freeze({
        id: key,
        // should have same values so take the first one
        agentId: matchPlayers[0].agent_id,
        playerId: teamPlayers[matchPlayers[0].team_player_id].player_id,
        teamPlayerId: matchPlayers[0].team_player_id,
        teamId: matchPlayers[0].team_id,
        maxUltimateOrbs: matchPlayers[0].max_ultimate_orbs,
        // set the matchPlayers references
        matchPlayerIds: matchPlayers.map(matchPlayers => matchPlayers.id),
        // set the match references
        matchIds: matchPlayers.map(matchPlayers => matchPlayers.match_id),
      })
    })
  }

  const MAX_DIST = 0.001
  const MAX_GAP = 2500
  const dist = (a, b) => Math.pow(a.location.x - b.location.x, 2) + Math.pow(a.location.y - b.location.y, 2)

  const filteredAdvancedPositions = filterSuspiciousAdvancedPositions
    ? Object.values(groupBy(advancedPositions || [], pos => `${pos.round_id}-${pos.gid}`)).flatMap(list => {
        list.sort((a, b) => a.round_time_millis - b.round_time_millis)
        return list.filter((e, i, a) => {
          if (
            i > 0 &&
            Math.abs(e.round_time_millis - a[i - 1].round_time_millis) < MAX_GAP &&
            dist(e, a[i - 1]) < MAX_DIST
          ) {
            return true
          } else {
            return false
          }
        })
      })
    : advancedPositions

  const posStats = Object.values(rounds).reduce(
    (acc, curr) => {
      acc.detected += curr.stats.positions.availablePositions
      acc.total += curr.stats.positions.totalPositions
      return acc
    },
    { detected: 0, total: 0 }
  )

  console.log(`detected ${Math.round((posStats.detected / posStats.total) * 10000) / 100}% of positions`)

  const data = postProcessData({
    agents: Object.freeze(agents),
    deaths: Object.freeze(deaths),
    defuses: Object.freeze(defuses),
    firstRoundKills: Object.freeze(firstRoundKills),
    gids: Object.freeze(gids),
    kills: Object.freeze(kills),
    matches: Object.freeze(matches),
    matchPlayers: Object.freeze(matchPlayers),
    matchTeams: Object.freeze(matchTeams),
    plants: Object.freeze(plants),
    players: Object.freeze(players),
    positions: Object.freeze(positions),
    roundPlayerUtilities: Object.freeze(roundPlayerUtilities),
    roundPlayerPositions: Object.freeze(roundPlayerPositions),
    rounds: Object.freeze(rounds),
    roundPlayers: Object.freeze(roundPlayers),
    roundTeams: Object.freeze(roundTeams),
    teamCompositions: Object.freeze(teamCompositions),
    teams: Object.freeze(teams),
    usedDamages: Object.freeze(usedDamages),
    advancedPositions: Object.freeze(filteredAdvancedPositions),
    smokes: Object.freeze(smokes),
    utilities: Object.freeze(utilities),
    walls: Object.freeze(walls),
    orbsPlayers: Object.freeze(orbsPlayers),
    zones: Object.freeze(zones),
    roundTeamStats: Object.freeze(roundStats),
    teamPlayers: Object.freeze(teamPlayers),
    utilitiesUsage: Object.freeze(utilitiesUsage),
    usedAbilities: Object.freeze(usedAbilities),
  })

  console.log('data', data)

  return data
}
